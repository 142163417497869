<template>
  <div class="main user-layout-register">
    <div class="tabs" v-if="pageType !== 'forgotPassword'">
      <router-link :to="{ name: 'login' }" style="color:#999;">{{ $t('user.login.title') }}</router-link>
      <router-link to="" style="border-bottom:4px solid #009CFF;">{{ $t('user.register.title') }}</router-link>
    </div>
    <div class="tabs forgetTabs" v-else-if="pageType == 'forgotPassword'">
      <span @click="$router.go(-1)"><a-icon type="left" />返回</span>
      <router-link to="" style="width:100px;color:#999;">{{ $t('user.forgotPassword.title') }}</router-link>
    </div>
    <a-form :form="form">
      <a-form-item
        ><!-- <a-form-item :validate-status="emailError() ? 'error' : ''" :help="emailError() || ''"> -->
        <a-input
          size="large"
          type="text"
          :placeholder="$t('user.register.emailPlaceholder')"
          v-decorator="[
            'email',
            { rules: [{ required: true, type: 'email', message: '请输入有效邮箱地址' }], validateTrigger: ['change'] }
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          size="large"
          type="text"
          :maxLength="4"
          :placeholder="$t('user.register.emailCode')"
          v-decorator="[
            'verificationCode',
            { rules: [{ required: true, message: '请输入有效验证码' }], validateTrigger: ['change'] }
          ]"
        >
        </a-input>
      </a-form-item>
      <span
        class="getCaptcha"
        size="large"
        :disabled="state.smsSendBtn"
        @click.stop.prevent="getCaptcha"
        v-text="(!state.smsSendBtn && $t('user.register.getEmailCode')) || state.time + ' s'"
      ></span>
      <a-form-item>
        <a-input-password
          size="large"
          :placeholder="$t('user.register.passwordPlaceholder')"
          v-decorator="[
            'password',
            {
              rules: [{ required: true, message: '请输入密码' }, { validator: this.handlepassword }],
              validateTrigger: ['change']
            }
          ]"
        ></a-input-password>
      </a-form-item>
      <a-form-item>
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="register-button"
          :loading="registerBtn"
          @click.stop.prevent="handleSubmit"
          :disabled="
            !form.getFieldsValue().email ||
              !form.getFieldsValue().password ||
              !form.getFieldsValue().verificationCode ||
              registerBtn
          "
          v-text="
            (pageType !== 'forgotPassword' && $t('user.register.title')) || $t('user.forgotPassword.resetSuccess')
          "
        >
        </a-button>
      </a-form-item>
      <a-form-item style="margin-top:18px;">
        <p class="register-tips" v-if="pageType !== 'forgotPassword'">* {{ $t('user.register.tips') }}</p>
      </a-form-item>
      <!-- <a-form-item v-if="pageType !== 'forgotPassword'">
        <a-checkbox v-decorator="['readProtocol', { valuePropName: 'checked' }]">我已阅读并同意用户协议和隐私政策</a-checkbox>
      </a-form-item> -->
    </a-form>
  </div>
</template>

<script>
import { getSmsCaptcha, register, resetPassword } from '@/api/register'
import { deviceMixin } from '@/store/device-mixin'
import { ROLES } from '@/store/mutation-types'
import storage from 'store'
import { mapActions } from 'vuex'
export default {
  name: 'Register',
  components: {},
  mixins: [deviceMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      pageType: undefined,
      state: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        percent: 10
      },
      registerBtn: false,
      interval: null
    }
  },
  created() {
    this.pageType = this.$route.query.type
  },
  watch: {
    // 由于注册和忘记密码使用同一组件 故监听路由完成已注册邮箱忘记密码
    $route: function(to, from) {
      this.state.time = 60
      // 可以拿到目标参数 to.query.id 去再次请求数据接口
      // console.log(to, this.$route.query.type)
      if (to.query.type) {
        this.pageType = this.$route.query.type
        // this.form.validateFields()
      } else {
        this.pageType = ''
      }
      this.state = {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        percent: 10
      }
      this.form.resetFields()
    }
  },
  methods: {
    ...mapActions(['Logout']),
    handlepassword(rule, value, callback) {
      console.log(1)
      const regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z~!@#$%^&*()_+`\-={}:";'<>?,./ ]{8,20}$/
      if (!regex.test(value)) {
        callback(new Error('密码为8-20位，必须包含字母和数字，支持常用符号'))
      } else {
        callback()
      }
    },
    // Submit提交数据
    handleSubmit() {
      const {
        form: { validateFields },
        pageType
        //  $router
      } = this
      validateFields({ force: true }, (err, values) => {
        if (!err) {
          const registerParams = { ...values }
          // 忘记密码
          if (pageType === 'forgotPassword') {
            this.handleForgetPassword(registerParams)
          } else {
            this.handleRegister(registerParams)
          }
        }
      })
    },
    // 注册
    handleRegister(data) {
      const _this = this
      data.origin = 'IOS'
      register(data)
        .then(res => {
          if (res.status === 200) {
            storage.set(ROLES, res.data.content.roles)
            _this.$router.push({ name: 'material', query: { type: res.data.content.token } })
          }
        })
        .catch(err => {
          console.log(err)
          _this.failedfuc(err.response)
        })
    },

    // 忘记密码
    handleForgetPassword(data) {
      const _this = this
      data.verificationType = 'EMAIL'
      // console.log(data)
      const recoverData = {
        password: data.password,
        target: data.email,
        verificationCode: data.verificationCode,
        verificationType: 'EMAIL'
      }
      resetPassword(recoverData)
        .then(res => {
          if (res.status === 200) {
            if (res.data.content) {
              this.$store.dispatch('Logout').then(() => {
                _this.$message.success('重置密码成功')
              })
              // 返回登录并传入邮箱地址
              _this.$router.push({ name: 'login', params: { email: data.email } })
            } else {
              this.$store.commit('showMessage', {
                message: '该邮箱还未注册',
                type: 'error'
              })

              // this.$confirm({
              //   title: '提示',
              //   content: '该邮箱还未注册',
              //   okText: '立即注册',
              //   cancelText: '取消',
              //   centered: true,
              //   onOk () {
              //     _this.$router.push({ path: 'register' })
              //   },
              //   onCancel () {}
              // })
            }
          }
        })
        .catch(err => {
          _this.failedfuc(err.response)
        })
    },
    // 发送验证码
    getCaptcha(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        $message
        // $notification
      } = this
      const _this = this
      validateFields(['email'], (err, values) => {
        if (!err) {
          state.smsSendBtn = true
          this.interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(_this.interval)
            }
          }, 1000)

          const hide = $message.loading('验证码发送中..', 0)

          getSmsCaptcha({ email: values.email })
            .then(res => {
              setTimeout(hide, 2500)
              // $notification['success']({
              //   message: '提示',
              //   description: '验证码获取成功，您的验证码为：' + res.data.content,
              //   duration: 8
              // })
            })
            .catch(err => {
              setTimeout(hide, 1)
              clearInterval(_this.interval)
              state.time = 60
              state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    failedfuc(err) {
      console.log(err)
      const _this = this
      if (err.data.errors && err.data.errors.email === 'validation.serviceCenter.email.exist') {
        this.$confirm({
          title: '提示',
          content: '该邮箱已注册',
          okText: '忘记密码',
          cancelText: '取消',
          centered: true,
          onOk() {
            _this.$router.push({ name: 'register', query: { type: 'forgotPassword' } })
          },
          onCancel() {}
        })
      } else if (err.data.errors && err.data.errors.teacher === 'validation.serviceCenter.teacher.notFound') {
        this.$success({
          title: '提示',
          content: '未检测到邮箱录入信息，请联系管理员',
          okText: '好的',
          centered: true,
          onOk() {},
          onCancel() {}
        })
      } else if (err.data.message && err.data.message === 'Invalid target to retrieve user account!') {
        this.$store.commit('showMessage', {
          message: '该邮箱还未注册',
          type: 'error'
        })
      } else if (err.data.errors && err.data.errors.newPassword === 'New password is the same as your old password') {
        this.$store.commit('showMessage', {
          message: '新密码不能和旧密码相同',
          type: 'error'
        })
      }
    },
    requestFailed(err) {
      this.$notification['error']({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
      this.registerBtn = false
    }
  }
}
</script>
<style lang="less">
.user-layout-register {
  .ant-input-group-addon:first-child {
    background-color: #fff;
  }
  .ant-input-password .ant-input-lg {
    height: 64px;
    border-radius: 14px;
    &:hover {
      border-color: #009cff !important;
      border-right-width: 1px !important;
    }
  }
}
</style>
<style lang="less" scoped>
.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  text-align: center;
  & a {
    display: inline-block;
    width: 80px;
    height: 50px;
    font-size: 24px;
    line-height: 50px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333 !important;
  }
}
.forgetTabs {
  display: block;
  span {
    font-size: 16px;
    color: #999;
    line-height: 50px;
    float: left;
    margin-left: 0;
  }
}
.user-layout-register {
  // .ant-row{
  //   margin-top: 36px;
  // }
  .ant-form-item {
    margin-bottom: 36px;
  }
  .ant-form-item-with-help {
    margin-bottom: 17px;
  }
  .ant-input,
  .ant-input-password .ant-input-lg {
    height: 64px;
    border-radius: 14px;
  }
  .ant-input:hover {
    border-color: #009cff !important;
    // border-right-width: 1px !important;
  }
  .getCaptcha {
    position: relative;
    max-width: 80px;
    border: none;
    color: #009cff;
    float: right;
    margin-top: -80px;
    margin-right: 40px;
    border-color: transparent;
  }
  .register-button {
    height: 64px;
    border-radius: 14px;
    width: 100%;
    font-size: 24px;
    color: #fff;
    // background-color: #009CFF;
    border: none;
  }
  .register-tips {
    font-size: 14px;
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-bottom: 0;
  }
}
</style>
