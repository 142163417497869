var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main user-layout-register"},[(_vm.pageType !== 'forgotPassword')?_c('div',{staticClass:"tabs"},[_c('router-link',{staticStyle:{"color":"#999"},attrs:{"to":{ name: 'login' }}},[_vm._v(_vm._s(_vm.$t('user.login.title')))]),_c('router-link',{staticStyle:{"border-bottom":"4px solid #009CFF"},attrs:{"to":""}},[_vm._v(_vm._s(_vm.$t('user.register.title')))])],1):(_vm.pageType == 'forgotPassword')?_c('div',{staticClass:"tabs forgetTabs"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('a-icon',{attrs:{"type":"left"}}),_vm._v("返回")],1),_c('router-link',{staticStyle:{"width":"100px","color":"#999"},attrs:{"to":""}},[_vm._v(_vm._s(_vm.$t('user.forgotPassword.title')))])],1):_vm._e(),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          { rules: [{ required: true, type: 'email', message: '请输入有效邮箱地址' }], validateTrigger: ['change'] }
        ]),expression:"[\n          'email',\n          { rules: [{ required: true, type: 'email', message: '请输入有效邮箱地址' }], validateTrigger: ['change'] }\n        ]"}],attrs:{"size":"large","type":"text","placeholder":_vm.$t('user.register.emailPlaceholder')}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'verificationCode',
          { rules: [{ required: true, message: '请输入有效验证码' }], validateTrigger: ['change'] }
        ]),expression:"[\n          'verificationCode',\n          { rules: [{ required: true, message: '请输入有效验证码' }], validateTrigger: ['change'] }\n        ]"}],attrs:{"size":"large","type":"text","maxLength":4,"placeholder":_vm.$t('user.register.emailCode')}})],1),_c('span',{staticClass:"getCaptcha",attrs:{"size":"large","disabled":_vm.state.smsSendBtn},domProps:{"textContent":_vm._s((!_vm.state.smsSendBtn && _vm.$t('user.register.getEmailCode')) || _vm.state.time + ' s')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getCaptcha.apply(null, arguments)}}}),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [{ required: true, message: '请输入密码' }, { validator: this.handlepassword }],
            validateTrigger: ['change']
          }
        ]),expression:"[\n          'password',\n          {\n            rules: [{ required: true, message: '请输入密码' }, { validator: this.handlepassword }],\n            validateTrigger: ['change']\n          }\n        ]"}],attrs:{"size":"large","placeholder":_vm.$t('user.register.passwordPlaceholder')}})],1),_c('a-form-item',[_c('a-button',{staticClass:"register-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.registerBtn,"disabled":!_vm.form.getFieldsValue().email ||
            !_vm.form.getFieldsValue().password ||
            !_vm.form.getFieldsValue().verificationCode ||
            _vm.registerBtn},domProps:{"textContent":_vm._s(
          (_vm.pageType !== 'forgotPassword' && _vm.$t('user.register.title')) || _vm.$t('user.forgotPassword.resetSuccess')
        )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}})],1),_c('a-form-item',{staticStyle:{"margin-top":"18px"}},[(_vm.pageType !== 'forgotPassword')?_c('p',{staticClass:"register-tips"},[_vm._v("* "+_vm._s(_vm.$t('user.register.tips')))]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }